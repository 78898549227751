import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import ActiveButton from '../../patientsBox/activeButton/ActiveButton'
import Circle from '../../../assets/svg/circle'
import MalariaNegativeIcon from '../../../assets/svg/MalariaNegativeIcon'
import { useEffect } from 'react'

interface IModal {
  confirm?: React.MouseEventHandler<HTMLButtonElement> | undefined
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
  closeTrigger: any
}

export default function MalariaNegativeModal({
  closeTrigger,
  confirm,
  onChange,
}: IModal) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTrigger])

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Circle />}
        variant={'outline'}
        border={'solid #6d3cfe'}
        color={'#6d3cfe'}
      >
        Malaria negative
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display={'flex'} gap={3}>
              <Box>
                <MalariaNegativeIcon />
              </Box>
              <Box>
                <Text pb={2}>Malaria Negative</Text>
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={'brand.lightGrayText'}
                >
                  Please provide your professional diagnosis and thoughts
                  explaining your conclusion
                </Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={5}>
              <Textarea
                onChange={onChange}
                placeholder="Write conclusion here"
              />
            </ModalBody>
            <Center>
              <ModalFooter w={'100%'}>
                <ActiveButton cancel={onClose} confirm={confirm} />
              </ModalFooter>
            </Center>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
