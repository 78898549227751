import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { CommentContext } from '../../../context/commentContext'
import { useGetVoteForCell } from '../../../api/api_hooks/vote'
import { useGetPrams } from '../../../utils/getPrams'
import { useGetImageCellsNew2 } from '../../../api/api_hooks/diagnostics'
import Check from '../../../assets/svg/check'

export default function VotingChart() {
  const { commentType } = useContext(CommentContext)
  const cellId = useGetPrams('cellId')
  const { result } = useGetVoteForCell(
    `/image-cells/${cellId}/votes/statistics`
  )
  const { result: singleImage } = useGetImageCellsNew2(
    `/shared-collections/image-cells/${cellId}`
  )

  const data = [
    { label: 'Positive', value: result?.malaria },
    { label: 'Negative', value: result?.negative },
    { label: 'Declined', value: result?.declined },
  ]

  const AIResult = ({ label }: { label: string }) => {
    const status = singleImage?.isAddedBySelf ? 'Negative' : 'Positive'
    return label === status ? (
      <Flex alignItems={'center'} gap={2}>
        <Check fill="#6d3cfe" />
        <Text color={'brand.purple'}>{'AI assistance result'}</Text>
      </Flex>
    ) : (
      <></>
    )
  }

  return (
    <Flex mt={10}>
      <VStack align={'start'} mr={2} gap={5} pt={5} pb={5}>
        {data.map((label, i) => (
          <Text key={i} h={'30px'}>
            {label.label}
          </Text>
        ))}
      </VStack>
      <Box w={'70%'}>
        <Box
          borderLeft={'1px solid #CCCCCC'}
          borderBottom={'1px solid #CCCCCC'}
          borderStyle={'dashed'}
          pl={2}
        >
          <Box display={'flex'} flexDirection={'column'} gap={5} pt={5} pb={5}>
            {data.map((label, i) => (
              <Box
                key={i}
                display={'flex'}
                w={'100%'}
                alignItems={'center'}
                gap={2}
                h={'30px'}
              >
                <Box
                  w={`${label.value}0%`}
                  background={commentType === i ? 'brand.purple' : 'brand.gray'}
                  h={3}
                  borderRadius={'0px 10px 10px 0px'}
                />
                <Text>{label.value}</Text>
                <AIResult label={label.label} />
              </Box>
            ))}
          </Box>
        </Box>
        <Flex justify={'space-between'} mt={3}>
          <Text>0</Text>
          <Text>2</Text>
          <Text>5</Text>
          <Text>7</Text>
          <Text>10</Text>
        </Flex>
      </Box>
    </Flex>
  )
}
