import React from 'react'

function Invite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <rect width="48" height="48" x="4" y="4" fill="#F4EBFF" rx="24"></rect>
      <rect
        width="48"
        height="48"
        x="4"
        y="4"
        stroke=""
        strokeWidth="8"
        rx="24"
      ></rect>
      <path
        stroke="#6d3cfe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M32 37v-2a4 4 0 00-4-4h-7a4 4 0 00-4 4v2M24.5 27a4 4 0 100-8 4 4 0 000 8zM36 24v6M39 27h-6"
      ></path>
    </svg>
  )
}

export default Invite
