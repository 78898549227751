import React, { useContext } from 'react'
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import ActiveButton from '../patientsBox/activeButton/ActiveButton'
import Invite from '../../assets/svg/invite'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { email } from '../../utils/schema'
import ControlInput from '../forms/formComponents/ControlInput'
import { useInvited } from '../../api/api_hooks/invite'
import CollaboratoryIcon from '../../assets/svg/collaboratoryIcon'
import { CommentContext } from '../../context/commentContext'

type Inputs = {
  email: string
}
interface IInviteColleagues {
  diagnosticID: string
}
export default function InviteColleagues({ diagnosticID }: IInviteColleagues) {
  const { myCollection } = useContext(CommentContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { mutateAsync } = useInvited(diagnosticID)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(email),
  })
  const onSubmit = (data: { email: string }) => {
    mutateAsync(data as unknown as void)
      .then(() => {
        toast({
          title: `Invited send - success`,
          position: 'top',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
      })
      .catch((e) => {
        toast({
          title:
            e.response.data.statusCode === 409
              ? 'Doctor already invited to this collection'
              : `Something went wrong - try again`,
          position: 'top',
          status: 'info',
          duration: 5000,
          isClosable: true,
        })
      })
  }

  return (
    <div>
      <Button
        onClick={onOpen}
        variant={'outline'}
        border={'solid #6d3cfe'}
        color={'#6d3cfe'}
        rightIcon={<CollaboratoryIcon />}
        isDisabled={myCollection?.length < 1}
      >
        Collaboratory
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display={'flex'} gap={3}>
              <Box mb={3}>
                <Invite />
              </Box>
              <Box>
                <Text pb={2}>Invite Colleagues</Text>
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={'brand.lightGrayText'}
                >
                  Enter email addresses to invite colleagues for collaborative
                  review and discussion of the diagnosed case.
                </Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={5}>
              <Text fontWeight={500}>Enter</Text>
              <ControlInput
                register={{ ...register('email') }}
                placeholder="Enter emails separated by commas"
                isInvalid={!!errors.email}
                errorMessage={errors.email && errors.email.message}
              />
            </ModalBody>
            <Center>
              <ModalFooter w={'100%'}>
                <ActiveButton
                  confirm={handleSubmit(onSubmit)}
                  confirmButtonText={'Send invites'}
                />
              </ModalFooter>
            </Center>
          </ModalContent>
        </Box>
      </Modal>
    </div>
  )
}
