import { extendTheme } from "@chakra-ui/react";
import "./index.css";

export const theme = extendTheme({
    fonts: {
        body: "Space Grotesk, Poppins",
        heading: "Space Grotesk,  Poppins",
    },
    colors: {
        brand: {
            purple: '#6d3cfe',
            gray: '#A5A5A5',
            lightGray: '#B3B3B3',
            lightGrayText: '#737A86',
            sideBarBlack: '#030303',
            opacityPurple:'#F9EEFE'
        }
    }
})